import React, { Component } from 'react';
import {
  
} from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from '../../constant/Utils';
import Header from '../common/Header';
import Footer from '../common/Footer';

import ImageGallery from "react-image-gallery";
import { Gallery, Item } from 'react-photoswipe-gallery';

import styled from '@emotion/styled';
import {Phone} from '@emotion-icons/fa-solid';

import { getHome } from '../../redux/action';
import "react-image-gallery/styles/css/image-gallery.css";
import 'photoswipe/dist/photoswipe.css'
import './home.css'; 


class About extends Component {

	constructor(props, context) {
	    super(props, context);
	    this.state = {
	    	property: {},
	    	banners:[],
	    };
    }

	componentWillMount() {

	}

	componentDidMount() {

		// getHome({}).then(res => {
		// 		this.setState({ 
					
		// 		})
		// 	}, this)
		
	}


	render() {

		
		return(
			<div>
				<Header />
				<div className="propertyContainer">
					
					<div className="">

						<div className="panelContainer">
							<div className="leftPanel">
								<h3>Company Vision</h3>	

								<p>
Unlocking Real Estate Potential with a Full Spectrum of Online Services In the realm of real
estate the journey from searching for the perfect property to making it your own is complex. 
<br/><br/>
Our platform offers a rich tapestry of services designed to
simplify this journey.
<br/><br/>
<b>Property Selection:</b> At the heart is our AI-driven property selection, represented by a web of
interconnected homes and icons that signify customization, indicating how our platform
connects clients to their ideal properties based on nuanced preferences.
Conveyancing: The conveyancing process is depicted as a streamlined owchart, highlighting
key milestones and featuring symbols for legal checks, document verication, and seamless
digital interactions, ensuring every step is transparent and ecient.

<br/><br/>
<b>Legal Services:</b> Our legal services are visualized through an icon of a gavel intertwined with a
digital screen, illustrating the fusion of traditional legal expertise with modern smart contract
technology to provide secure and compliant transactions.
Transaction Processing: The graphic shows a stylized ledger or digital interface, indicating our
platform's ability to process transactions with speed and accuracy, employing encrypted data
transfer to ensure condentiality and integrity.

<br/><br/>
<b>Letting Management:</b> For property investors, our letting management services are shown as a
dashboard with tools for tenant screening, rent collection, and maintenance requests, offering a
hassle-free approach to property management.

<br/><br/>
<b>Mortgage and Financial Services:</b> Lastly, the infographic features symbols of calculators and
nancial charts, representing our mortgage and nancial services that assist users with loan
comparisons, applications, and nancial planning, all integrated into the platform for a smooth
user experience.

								</p>
								
							</div>

							<div className="rightPanel">
								<div className="stickyPanel">

								</div>

								
							</div>

						</div>

					</div>

				</div>
				<Footer />
			</div>
		);
	}

}

const mapStateToProps = (state) => {
  return {

  };
}

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch)

export default withRouter(connect(
  mapStateToProps,
  {  }
)(About));