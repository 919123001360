import { createStore, applyMiddleware, Store } from 'redux';  
import rootReducer from './reducer/rootReducer';  
import thunk from 'redux-thunk';
import promise from 'redux-promise';

let store: Store;

export default function configureStore() {  
	if (!store) {
		store = createStore(rootReducer, applyMiddleware(promise,thunk))
	}
	return store
}