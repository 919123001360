import React, { Component } from 'react';
import {
  
} from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from '../../constant/Utils';

import logo from '../../images/logo.png';
import menuIcon from '../../images/menuIcon.png';

import './header.css'; 

class Header extends Component {

	constructor(props, context) {
	    super(props, context);
	    this.state = {
	    	menuOpened: false,
	    	geOpened: false,
	    	aeOpened: false,
	    	searchText: "",
	    	userToken: ""
	    };
    }

	componentWillMount() {
		let token = window.localStorage.getItem("token");
		if (token) {
			this.setState({ userToken: token });
		}
	}


	render() {


		return(
			<div>
				<div className="header containerMaxWidth">
					<a className="headerLogo" href="/"><img src={logo} /></a>
					<div className="desktopMenu">
						
						<div className="headerMenu">
							<div className="flexBox">
								<a href="/" className={(this.props.urlKey === 'case-sharing') ? "headerMenuItem active" : "headerMenuItem" }><div><span>Explore</span></div></a>
								<a href="/about" className={(this.props.urlKey === 'news-and-updates') ? "headerMenuItem active" : "headerMenuItem" }><div><span>About Us</span></div></a>
								<a href="/contact" className={(this.props.urlKey === 'news-and-updates') ? "headerMenuItem active" : "headerMenuItem" }><div><span>Contact Us</span></div></a>
								{ 
									(this.state.userToken) ? (
										<a href="/mission" className={(this.props.urlKey === 'news-and-updates') ? "headerMenuItem active" : "headerMenuItem" }><div><span>My Swap</span></div></a>
									) : ""
								}	
							</div>
							<div className="supperHeader">
									{
										(this.state.userToken) ? (
											<div className="loginInput actionBtn">
												<a>
													<div>
														<span>MY ACCOUNT</span>
													</div>
												</a>
											</div>

										) : (
											<div className="loginInput actionBtn">
												<a href="/login">
													<div>
														<span>LOGIN</span>
													</div>
												</a>
											</div>
										)
									}
									
								</div>
						</div>
					</div>

					<div className="mobileMenu">
						<img className="menuIcon" src={menuIcon} onClick={() => { this.setState({ menuOpened: true }) }} />
						<div className={ this.state.menuOpened ? "dimPopup active" : "dimPopup" } onClick={() => { this.setState({ menuOpened: false }) }} />
						<div className={ this.state.menuOpened ? "mobileMenuBox active" : "mobileMenuBox" }>
							<div className="supperHeader">
								
							</div>
							<div className="headerMenu">
								<a href="/" className={(this.props.urlKey === 'case-sharing') ? "headerMenuItem active" : "headerMenuItem" }><div><span>Explore</span></div></a>
								<a href="/about" className={(this.props.urlKey === 'news-and-updates') ? "headerMenuItem active" : "headerMenuItem" }><div><span>About Us</span></div></a>

								<a href="/contact" className={(this.props.urlKey === 'news-and-updates') ? "headerMenuItem active" : "headerMenuItem" }><div><span>Contact Us</span></div></a>							

								{ 
									(this.state.userToken) ? (
										<a href="/mission" className={(this.props.urlKey === 'news-and-updates') ? "headerMenuItem active" : "headerMenuItem" }><div><span>My Swap</span></div></a>
									) : ""
								}
							</div>
								
							<div className="supperHeader">

								{
									(this.state.userToken) ? (
										<div className="loginInput actionBtn">
											<a>
												<div>
													<span>MY ACCOUNT</span>
												</div>
											</a>
										</div>

									) : (
										<div className="loginInput actionBtn">
											<a href="/login">
												<div>
													<span>LOGIN</span>
												</div>
											</a>
										</div>
									)
								}


							</div>
						</div>
						
					</div>
				</div>
			</div>
		);
	}

}

const mapStateToProps = (state) => {
  return {

  };
}

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch)

export default withRouter(connect(
  mapStateToProps,
  {  }
)(Header));