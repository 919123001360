import axios from 'axios';

export const USER_REGISTER = 'USER_REGISTER';
export const USER_LOGIN = 'USER_LOGIN';

export const API_BASE_URL = 'https://swapapi.demo-uat.com';


export function login($body) {
  const request = axios.post(API_BASE_URL+'/api/login', $body );
  //place static json here for dev if api is not ready yet

  return request;
}

export function register($body) {
  const request = axios.post(API_BASE_URL+'/api/register', $body );
  //place static json here for dev if api is not ready yet

  return request;
}

export function requestAgent($body) {
  const request = axios.post(API_BASE_URL+'/api/requestAgent', $body );
  //place static json here for dev if api is not ready yet

  return request;
}
export function postMessage($body) {
  const request = axios.post(API_BASE_URL+'/api/createMessage', $body );
  //place static json here for dev if api is not ready yet

  return request;
}
export function getChat(token) {
  const request = axios.get(API_BASE_URL+'/api/orders/'+token);
  //place static json here for dev if api is not ready yet

  return request;
}






