import React, { Fragment } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

import Home from '../components/landing/Home';
import Property from '../components/housing/Property';
import Agent from '../components/landing/Agent';
import About from '../components/landing/About';
import Contact from '../components/landing/Contact';
import Login from '../components/landing/Login';
import Register from '../components/landing/Register';
import Mission from '../components/landing/Mission';

export function RenderRoutes({ routes }) {
  return (
    <Routes>
      <Route path="/" key="ROOT" exact element={<Home urlKey="root" />} />
      <Route path="/property/:code" key="Property" exact element={<Property urlKey="property" />} />
      <Route path="/agents" key="Agent" exact element={<Agent urlKey="property" />} />
      <Route path="/about" key="About" exact element={<About urlKey="property" />} />
      <Route path="/contact" key="Contact" exact element={<Contact urlKey="property" />} />
      <Route path="/terms" key="Property" exact element={<Property urlKey="property" />} />
      <Route path="/login" key="Account" exact element={<Login urlKey="property" />} />
      <Route path="/register" key="Account" exact element={<Register urlKey="property" />} />
      <Route path="/mission" key="Account" exact element={<Mission urlKey="property" />} />

      <Route element={() => <h1>Not Found!</h1>} />
    </Routes>
  );
}