import { ThemeProvider, createTheme } from '@mui/material/styles';

import logo from './logo.svg';
import './App.css';

import { RenderRoutes } from "./routes/routes";

import { BrowserRouter as BrowserRouter } from 'react-router-dom';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Helvetica Neue',
      'Raleway',
      'Roboto',
      'Arial',
      'sans-serif'
    ].join(','),
  }
});

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <div>
          <RenderRoutes />
        </div>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
