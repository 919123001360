import React, { Component } from 'react';
import {
  
} from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from '../../constant/Utils';
import Header from '../common/Header';
import Footer from '../common/Footer';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid'; 
import Paper from '@mui/material/Paper';

import GoogleMapsAutoComplete from '../util/GoogleMapsAutoComplete';

import { getHome } from '../../redux/action';
import './home.css'; 
import banner from '../../images/backgroundBanner.png';

import styled from '@emotion/styled';
import { Hot, Hotel } from '@emotion-icons/boxicons-solid/';
import { HomeHeart } from '@emotion-icons/boxicons-regular/HomeHeart';
import { ChildFriendly } from '@emotion-icons/material/ChildFriendly';
import { BatterySaver } from '@emotion-icons/fluentui-system-filled/BatterySaver';
import { BurstSale } from '@emotion-icons/foundation/BurstSale';
import { Building } from '@emotion-icons/fluentui-system-filled/Building';
import { DesignIdeas } from '@emotion-icons/fluentui-system-regular/DesignIdeas';

import { Photo } from '@emotion-icons/material-outlined/Photo';
import { New } from '@emotion-icons/fluentui-system-filled/New';
import { Plant } from '@emotion-icons/remix-fill/Plant';


class Home extends Component {

	constructor(props, context) {
	    super(props, context);
	    this.state = {
	    	filterType: 0,
	    	destionations: [],
	    	propertyType: ["Houses","Flat/Apartments","Bungalows","Land","Commercial Property", "Other"],
	    	minRentPrice: [100,150,200,250,300,350,400,500,600,700,800,900,1000,1500,2000,3000,4000,5000],
	    	maxRentPrice: [100,150,200,250,300,350,400,500,600,700,800,900,1000,1500,2000,3000,4000,5000],
	    	minBuyPrice: [50000,60000,70000,80000,90000,100000,150000,200000,300000,400000,500000,700000,10000000],
	    	maxBuyPrice: [50000,60000,70000,80000,90000,100000,150000,200000,300000,400000,500000,700000,10000000],
	    	minBed: [0,1,2,3,4,5],
	    	maxBed: [0,1,2,3,4,5],
	    	keyWord: "",
	    	searchPropertyType: "",
	    	searchCategory: "",
	    	searchMaxPrice: 0,
	    	searchMinPrice: 0,
	    	rawDagta: [],
	    	data: [
	    		{
	    			id: 1,
	    			name: "Room in Greater London, United Kingdom",
	    			type: "House",
	    			price: "298",
	    			paidType: "rental",
	    			thumbnail: "https://a0.muscache.com/im/pictures/b90b5ab0-909f-41a0-8d43-97ed918d5242.jpg?im_w=720",
	    			cats: ["trend","new"],
	    		},
	    		{
	    			id: 2,
	    			name: "Little Venice Garden Flat",
	    			type: "Flat",
	    			price: "592",
	    			paidType: "rental",
	    			thumbnail: "https://a0.muscache.com/im/pictures/848e5c3d-0aec-4aac-a0e5-60121244cbde.jpg?im_w=1200",
	    			cats: ["trend","bed"],
	    		},
	    		{
	    			id: 3,
	    			name: "Luxury apartment in the heart of Kensington",
	    			type: "House",
	    			price: "1298",
	    			paidType: "rental",
	    			thumbnail: "https://a0.muscache.com/im/pictures/hosting/Hosting-U3RheVN1cHBseUxpc3Rpbmc6MjkyNzY4ODA%3D/original/516dcc52-06c1-4efe-99cf-664cebc4b9ba.jpeg?im_w=1200",
	    			cats: ["trend","childFriendly"],
	    		},
	    		{
	    			id: 4,
	    			name: "St Albans Grove - 1 Bed Cottage in Kensington",
	    			type: "House",
	    			price: "798",
	    			paidType: "rental",
	    			thumbnail: "https://a0.muscache.com/im/pictures/9f8e380e-8619-423e-8d02-68727181906a.jpg?im_w=1200",
	    			cats: ["trend","new"],
	    		},
	    		{
	    			id: 5,
	    			name: "Room in Greater London, United Kingdom",
	    			type: "House",
	    			price: "298000",
	    			paidType: "sale",
	    			thumbnail: "https://a0.muscache.com/im/pictures/b90b5ab0-909f-41a0-8d43-97ed918d5242.jpg?im_w=720",
	    			cats: ["trend","farm"],
	    		},
	    		{
	    			id: 6,
	    			name: "Little Venice Garden Flat",
	    			type: "Flat",
	    			price: "592000",
	    			paidType: "sale",
	    			thumbnail: "https://a0.muscache.com/im/pictures/848e5c3d-0aec-4aac-a0e5-60121244cbde.jpg?im_w=1200",
	    			cats: ["trend","new"],
	    		},
	    		{
	    			id: 7,
	    			name: "Luxury apartment in the heart of Kensington",
	    			type: "House",
	    			price: "1298000",
	    			paidType: "sale",
	    			thumbnail: "https://a0.muscache.com/im/pictures/hosting/Hosting-U3RheVN1cHBseUxpc3Rpbmc6MjkyNzY4ODA%3D/original/516dcc52-06c1-4efe-99cf-664cebc4b9ba.jpeg?im_w=1200",
	    			cats: ["trend","new"],
	    		},
	    		{
	    			id: 8,
	    			name: "St Albans Grove - 1 Bed Cottage in Kensington",
	    			type: "House",
	    			price: "798000",
	    			paidType: "sale",
	    			thumbnail: "https://a0.muscache.com/im/pictures/9f8e380e-8619-423e-8d02-68727181906a.jpg?im_w=1200",
	    			cats: ["trend","new"],
	    		},
	    	],
	    };
    }

	componentWillMount() {

	}

	componentDidMount() {


		this.setState({
			rawData: this.state.data
		});

		
		// getHome({}).then(res => {
		// 		this.setState({ 
					
		// 		})
		// 	}, this)
		
	}

	onTabChanged(e) {
		this.setState({ filterType: (this.state.filterType == 0) ? 1 : 0, keyWord: "", searchMaxPrice: 0, searchMinPrice: 0, searchPropertyType: "" });

	}

	onCategoryChanged(word) {
		console.log(word);
		this.setState({ searchCategory: word }, () => {
			console.log(this.state.searchCategory);
			this.findProperty();

		});
	}

	findProperty() {

		let cloneData = this.state.rawData;
		if (this.state.keyWord.length > 0) {
			cloneData = cloneData.filter(item => item.name.toLowerCase().includes(this.state.keyWord.toLowerCase()))
		}
		if (this.state.searchPropertyType.length > 0) {
			cloneData = cloneData.filter(item => this.state.searchPropertyType.toLowerCase().includes(item.type.toLowerCase().toLowerCase()))
		}
		if (this.state.searchMinPrice.length > 0) {
			cloneData = cloneData.filter(item => parseInt(item.price) >= this.state.searchMinPrice)
		}
		if (this.state.searchMaxPrice.length > 0) {
			cloneData = cloneData.filter(item => parseInt(item.price) <= this.state.searchMaxPrice)
		}
		
		if (this.state.searchCategory.length > 0) {
			cloneData = cloneData.filter(item => item.cats.some(i => i === this.state.searchCategory) )
		}
		if (this.state.filterType > 0) {
			cloneData = cloneData.filter(item => item.paidType == "rental" )
		} else {
			cloneData = cloneData.filter(item => item.paidType == "sale" )
		}


		this.setState({
			data: cloneData
		});
	}


	render() {

		return(
			<div>
				<Header />
				<div className="mainContainer">

					<div className="homeBanner">
						<img src={banner} />
					</div>
					
					<div className="">
						<Box className="homeFilterBox">
							<Tabs centered className="homeFilter" aria-label="basic tabs" value={this.state.filterType} onChange={(e) => this.onTabChanged(e)}>
								<Tab className="homeFilterTab" label="Buy" id="0" textColor="inherit" />
								<Tab className="homeFilterTab" label="Rent" id="1" textColor="inherit" />
							</Tabs>
						</Box>

			      <div className="tabpanel" role="tabpanel" hidden={this.state.filterType !== 0} id={0}>
			        
			      	<GoogleMapsAutoComplete
				      	freeSolo
				      	className="filterItem"
			      		onInputChange={(event, newValue) => {
			      			console.log(newValue);
				          this.setState({ keyWord: newValue.trim().split(',')[0] });
				        }}
			      		renderInput={(params) => <TextField {...params} label="Location" /> }
			      		/>

			      	<Autocomplete
					      	size="small"
				      	className="filterItem"
			      		onInputChange={(event, newValue) => {
				          this.setState({ searchPropertyType: newValue });
				        }}
			      		options={this.state.propertyType}
			      		renderInput={(params) => <TextField {...params} label="Property Type" /> }
			      		/>

		      		<div className="flexBox">

				      	<Autocomplete
					      	size="small"
				      		onInputChange={(event, newValue) => {
					          this.setState({ searchMinPrice: newValue });
					        }}
					      	className="filterItem halfWidth"
				      		options={this.state.minBuyPrice}
				      		renderInput={(params) => <TextField {...params} label="min Price" /> }
				      		/>

				      	<Autocomplete
					      	size="small"
				      		onInputChange={(event, newValue) => {
					          this.setState({ searchMaxPrice: newValue });
					        }}
					      	className="filterItem halfWidth"
				      		options={this.state.maxBuyPrice}
				      		renderInput={(params) => <TextField {...params} label="max Price" /> }
				      		/>

				      	<Autocomplete
					      	size="small"
					      	className="filterItem halfWidth"
				      		options={this.state.minBed}
				      		renderInput={(params) => <TextField {...params} label="min Bedrooms" /> }
				      		/>

				      	<Autocomplete
					      	size="small"
					      	className="filterItem halfWidth"
				      		options={this.state.maxBed}
				      		renderInput={(params) => <TextField {...params} label="max Bedrooms" /> }
				      		/>

		      		</div>

		      		<div className="flexBox">


		      		</div>

			      </div>

			      <div className="tabpanel" role="tabpanel" hidden={this.state.filterType !== 1} id={1} >
			        
			      	<Autocomplete
				      	freeSolo
				      	size="small"
			      		onInputChange={(event, newValue) => {
				          this.setState({ keyWord: newValue });
				        }}
				      	className="filterItem"
			      		options={this.state.destionations}
			      		renderInput={(params) => <TextField {...params} label="Location" /> }
			      		/>

			      	<Autocomplete
				      	size="small"
				      	className="filterItem"
			      		options={this.state.propertyType}
			      		onChange={(event, newValue) => {
			      			console.log(newValue);
				          this.setState({ searchPropertyType: newValue });
				        }}
			      		renderInput={(params) => <TextField {...params} label="Property Type" /> }
			      		/>

		      		<div className="flexBox">

				      	<Autocomplete
					      	size="small"
				      		onInputChange={(event, newValue) => {
					          this.setState({ searchMinPrice: newValue.replace("PCM","") });
					        }}
					      	className="filterItem halfWidth"
				      		options={this.state.minRentPrice.map(item => item + " PCM")}
				      		renderInput={(params) => <TextField {...params} label="min Price" /> }
				      		/>

				      	<Autocomplete
					      	size="small"
				      		onInputChange={(event, newValue) => {
					          this.setState({ searchMaxPrice: newValue.replace("PCM","") });
					        }}
					      	className="filterItem halfWidth"
				      		options={this.state.maxRentPrice.map(item => item + " PCM")}
				      		renderInput={(params) => <TextField {...params} label="max Price" /> }
				      		/>

				      	<Autocomplete
					      	size="small"
					      	className="filterItem halfWidth"
				      		options={this.state.minBed}
				      		renderInput={(params) => <TextField {...params} label="min Bedrooms" /> }
				      		/>

				      	<Autocomplete
					      	size="small"
					      	className="filterItem halfWidth"
				      		options={this.state.maxBed}
				      		renderInput={(params) => <TextField {...params} label="max Bedrooms" /> }
				      		/>

		      		</div>

		      		<div className="flexBox">


		      		</div>

			      </div>

	      		<div className="btnGroup">
				      <Button className="searchBtn" size="large" variant="contained" onClick={(e) => this.findProperty()}>Find Property</Button>
			      </div>

					</div>

					<div className="categoryList">

						<div className={this.state.searchCategory == "" ? "categoryItem active" : "categoryItem"} onClick={(e) => this.onCategoryChanged("")}>
							<HomeHeart />
							<span>All</span>
						</div>
						<div className={this.state.searchCategory == "trend" ? "categoryItem active" : "categoryItem"} onClick={(e) => this.onCategoryChanged("trend")}>
							<Hot />
							<span>Trending</span>
						</div>
						<div className={this.state.searchCategory == "new" ? "categoryItem active" : "categoryItem"} onClick={(e) => this.onCategoryChanged("new")}>
							<New />
							<span>New</span>
						</div>
						<div className={this.state.searchCategory == "bed" ? "categoryItem active" : "categoryItem"} onClick={(e) => this.onCategoryChanged("bed")}>
							<Hotel />
							<span>Bedroom</span>
						</div>
						<div className={this.state.searchCategory == "childFriendly" ? "categoryItem active" : "categoryItem"} onClick={(e) => this.onCategoryChanged("childFriendly")}>
							<ChildFriendly />
							<span>Child Friendly</span>
						</div>
						<div className={this.state.searchCategory == "eco" ? "categoryItem active" : "categoryItem"} onClick={(e) => this.onCategoryChanged("eco")}>
							<BatterySaver />
							<span>Eco Friendly</span>
						</div>
						<div className={this.state.searchCategory == "design" ? "categoryItem active" : "categoryItem"} onClick={(e) => this.onCategoryChanged("design")}>
							<DesignIdeas />
							<span>Design</span>
						</div>
						<div className={this.state.searchCategory == "building" ? "categoryItem active" : "categoryItem"} onClick={(e) => this.onCategoryChanged("building")}>
							<Building />
							<span>Apartment</span>
						</div>
						<div className={this.state.searchCategory == "sale" ? "categoryItem active" : "categoryItem"} onClick={(e) => this.onCategoryChanged("sale")}>
							<BurstSale />
							<span>Sale</span>
						</div>
						<div className={this.state.searchCategory == "view" ? "categoryItem active" : "categoryItem"} onClick={(e) => this.onCategoryChanged("view")}>
							<Photo />
							<span>Amazing Views</span>
						</div>
						<div className={this.state.searchCategory == "farm" ? "categoryItem active" : "categoryItem"} onClick={(e) => this.onCategoryChanged("farm")}>
							<Plant />
							<span>Farm</span>
						</div>

					</div>


					<div className="searchResultGrid">

						<Grid container spacing={{md: 2, xs: 0}}>
							{
								(this.state.data).map(item => {
									return (
											<Grid className="searchResultGridItem" item xs={12} md={4} lg={3}>
												<Paper>
													<a href={"/property/"+item.id}>
														<div className="propertyItem">
															<img className="propertyItemBanner" src={item.thumbnail} />
															<div className="propertyItemBox">
																<p className="propertyItemName">{item.name}</p>
																<p className="propertyItemType">{item.type}</p>
																<p className="propertyItemPrice"><b>£{item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</b> {(item.paidType == "rental") ? "pcm" : ""}</p>
															</div>
														</div>
													</a>
												</Paper>
											</Grid>
										)
								})
							}
							

						</Grid>

					</div>

				</div>
				<Footer />
			</div>
		);
	}

}

const mapStateToProps = (state) => {
  return {

  };
}

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch)

export default withRouter(connect(
  mapStateToProps,
  {  }
)(Home));