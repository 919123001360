import React, { Component } from 'react';
import {
  
} from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from '../../constant/Utils';

import './footer.css'; 

class Footer extends Component {

	constructor(props, context) {
	    super(props, context);
	    this.state = {
	    	
	    };
    }

	componentWillMount() {

	}


	render() {

		
		const headerArr = [
			
			];

		return(
			<div className="footer">
				<div className="containerMaxWidth">
					<div className="desktopFooter">
						{
							headerArr.map((item, idx) => {
								return (
									<a href={item.url} className={(this.props.urlKey === item.keyUrl) ? "menuItem active" : "menuItem" }>
										<div>	
											<span>{item.name}</span>
										</div>
									</a>
								);
							})
						}
					</div>
					<div className="footerLogoContainer">
						<div className="footerLogo" ></div>
					</div>
					
				</div>
				<div className="tcBox containerMaxWidth" >
					
					<div style={{  }} ><span>© 2024 Swaptify Ltd. All Rights Reserved.</span></div>
					<div onClick={(e) => { window.location.href = "/terms" }} ><a><span>Privacy Policy</span></a></div>
					
				</div>
			</div>
		);
	}

}

const mapStateToProps = (state) => {
  return {

  };
}

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch)

export default withRouter(connect(
  mapStateToProps,
  {  }
)(Footer));