import React, { Component } from 'react';
import {
  
} from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from '../../constant/Utils';
import Header from '../common/Header';
import Footer from '../common/Footer';

import ImageGallery from "react-image-gallery";
import { Gallery, Item } from 'react-photoswipe-gallery';

import { ChatList, MessageList, Input, Button } from "react-chat-elements";

import styled from '@emotion/styled';
import {Phone} from '@emotion-icons/fa-solid';
import {Send} from '@emotion-icons/boxicons-solid/Send';
import {Task} from '@emotion-icons/remix-line/Task';
import { Task as TaskFill } from '@emotion-icons/remix-fill/Task';
import { Paperclip } from '@emotion-icons/bootstrap/Paperclip';
import { Cross } from '@emotion-icons/entypo/Cross';


import { getHome, getChat, postMessage } from '../../redux/action';
import "react-image-gallery/styles/css/image-gallery.css";
import 'photoswipe/dist/photoswipe.css'
import './mission.css'; 
import 'react-chat-elements/dist/main.css'


class Mission extends Component {

	constructor(props, context) {
	    super(props, context);
	    this.input = React.createRef();
	    this.messagesEndRef = React.createRef();
	    this.fileUpload = React.createRef();
	    this.state = {
	    	property: {},
	    	banners:[],
	    	closeRight: true,
	    	clientMessage: "",
	    	currentChatId: 0,
	    	currentTaskId: 0,
	    	selectedFile: null,
	    	toDoStage: null,
	    	messages: [

		    ],
    		chatList: [
							    
				],

	    };

    }

	componentWillMount() {

	}

	componentDidMount() {

		if (window.localStorage.getItem("token")) {



			getChat(window.localStorage.getItem("token")).then((res) => {
				let chat = res.data.data.order_data;
				this.setState({ 
					chatList: chat,
					messages: chat[0].tasks[0] ? chat[0].tasks[0].messages : [], 
					toDoStage: chat[0].tasks[0] ? { max: chat[0].tasks.length, current: 0 } : null,
					currentChatId: chat ? chat[0].id : 0,
				});
			});

		} else {
			window.location.href = "/";
		}


		// getHome({}).then(res => {
		// 		this.setState({ 
					
		// 		})
		// 	}, this)
		
	}

	updateChat(idx) {
		let tasks = this.state.chatList.filter(item => item.id == idx)[0].tasks;
		this.setState({ 
			messages: tasks[0] ? tasks[0].messages : [], 
			toDoStage: tasks[0] ? { max: tasks.length, current: 0 } : null,
			currentChatId: idx,
			currentTaskId: tasks[0] ? tasks[0].id  : 0,
			closeRight: false,
		});
	}

	componentDidUpdate() {

	}

	postMessage() {
		let newMessage = {
					position:"right",
		      type:"text",
		      title:"",
		      text: this.input.current.value,
		};
		let newMessageData = {
					conversation_id: this.state.currentChatId,
					task_id: this.state.currentTaskId,
					position: 0,
		      type:"text",
		      title:"",
		      message: this.input.current.value,
		};
		postMessage(newMessageData).then(res => {
			this.updateMessageList(newMessage);
		});
	}

	updateMessageList(newMessage) {
		if (this.state.messages) {
			console.log("update");

			let newMessageArr = [...this.state.messages, newMessage];
			let currentChatList = (this.state.chatList || []).map(item => {
				if (item.id == this.state.currentChatId) {
					console.log(item.tasks[0]);
					if (item.tasks[0]) {
						item.tasks[0].messages = newMessageArr
					}
				}
				return item;
			});
			this.setState({ chatList: currentChatList, messages: newMessageArr });
			this.input.current.value = "";
			this.messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });

		}

	}

	onFileChange = event => {
    this.setState({ selectedFile: event.target.files[0] });

    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append(
      "myFile",
      event.target.files[0] ,
      event.target.files[0].name
    );

    // Details of the uploaded file
    console.log(formData);

    // Request made to the backend api
    // Send formData object
    // axios.post("api/uploadfile", formData);

    let newMessage = {
					position:"right",
		      type:"file",
		      title:"",
		      text: event.target.files[0].name,
		      data: {
		      	uri: event.target.files[0],
				    status: {
				      click: false,
				      loading: 0,
				    },
		      }
		};

		let newMessageData = {
					conversation_id: this.state.currentChatId,
					task_id: this.state.currentTaskId,
					position: 0,
		      type:"file",
		      title:"",
		      file: event.target.files[0],
		      message: event.target.files[0].name,
		};
		postMessage(newMessageData).then(res => {
			this.updateMessageList(newMessage);
		});
  };

  onFileUpload = () => {
  };



	render() {

		
		return(
			<div>
				<Header />
				<div className="missionContainer">
					
					<div className="">

						<div className="panelContainer">
							<div className="leftPanel">

								<ChatList
								  className='chat-list'
								  onClick={(e) => this.updateChat(e.id)}
								  dataSource={this.state.chatList} />
								
								
							</div>

							<div className={(this.state.closeRight) ? "rightPanel hidden" : "rightPanel"}>
								<div className="stickyPanel">
									<Cross onClick={(e) => this.setState({ closeRight: true })} className="closeBtn mobileBtn" />
									{
										(this.state.toDoStage) ? (
											<div className="toDoList">
												<div className="separator middle" style={{ width: ((window.innerWidth < 768 && (this.state.toDoStage.max-1) * 66 >= window.innerWidth - 24) ? ((this.state.toDoStage.max-1) * 66)+"px" : "auto") }}></div>
												{
													this.state.chatList.filter(item => item.id == this.state.currentChatId)[0].tasks.map((item, idx) => {
														return (
															<div className="taskItem" onClick={(e) => this.setState({ currentTaskId: item.id })}>
																{
																	(item.status == 2) ? <p className="task active" >	&#10003;</p> : <p className="task" >{idx+1}</p>
																}
															</div>
														)
													})
												}
											</div>
										) : ""
									}
									<MessageList
									    className='messageList'
									    referance={this.messagesEndRef}
									    lockable={true}
									    toBottomHeight={'100%'}
									    dataSource={this.state.messages}
									/>

									<div className="typeFlex">

										<Input
										  placeholder="Type here"
										  multiline={true}
										  referance={this.input}
										  onChange={(e) => this.setState({ clientMessage: e })}
										  inputStyle={{ "border": "1px solid #cdcdcf" }}
										/>

										<Button className="typeArea" text={""} onClick={() => this.fileUpload.current.click() } 
											title="" 
											icon={{
												component: <Paperclip style={{ "width": "18px" }} /> }} 
											/>

										<Input 
										  referance={this.fileUpload} className="fileType" type="file" onChange={this.onFileChange} />

										<Button className="typeArea" text={""} onClick={() => this.postMessage() } 
											title="" 
											icon={{
												component: <Send style={{ "width": "18px" }} /> }} 
											/>

									</div>

								</div>

								
							</div>

						</div>

					</div>

				</div>
				<Footer />
			</div>
		);
	}

}

const mapStateToProps = (state) => {
  return {

  };
}

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch)

export default withRouter(connect(
  mapStateToProps,
  {  }
)(Mission));