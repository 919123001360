import React, { Component } from 'react';
import {
  
} from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from '../../constant/Utils';
import Header from '../common/Header';
import Footer from '../common/Footer';

import ImageGallery from "react-image-gallery";
import { Gallery, Item } from 'react-photoswipe-gallery';

import styled from '@emotion/styled';
import {Phone} from '@emotion-icons/fa-solid';

import { getHome } from '../../redux/action';
import "react-image-gallery/styles/css/image-gallery.css";
import 'photoswipe/dist/photoswipe.css'
import './home.css'; 


class Contact extends Component {

	constructor(props, context) {
	    super(props, context);
	    this.state = {
	    	property: {},
	    	banners:[],
	    };
    }

	componentWillMount() {

	}

	componentDidMount() {

		// getHome({}).then(res => {
		// 		this.setState({ 
					
		// 		})
		// 	}, this)
		
	}


	render() {

		
		return(
			<div>
				<Header />
				<div className="propertyContainer contactContainer">
					
					<div className="">

						<div className="panelContainer">
							<div className="leftPanel">
								<h3>Contact Us</h3>
								<p>
								Selling or renting your home and would like to advertise?
								That's great! Simply select from one of our many member agents advertising in your area, enter your location in to our 'Agents' search, then select and instruct your property with your chosen member agent.
								</p>
								<h4>Estate & Lettings Agents</h4>
								<p>info here</p>

								<h4>Estate Agents</h4>
								<p>info here</p>

								<h4>Commercial Agents & Landlords</h4>
								<p>info here</p>

								<h4>Advertising</h4>
								<p>info here</p>


								<h4>Private Sellers</h4>
								<p>info here</p>


								<h4>Head office</h4>
								<p>Address:</p>
								<p>Tel:</p>
								<p>Email:</p>

								
							</div>

						</div>

					</div>

				</div>
				<Footer />
			</div>
		);
	}

}

const mapStateToProps = (state) => {
  return {

  };
}

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch)

export default withRouter(connect(
  mapStateToProps,
  {  }
)(Contact));