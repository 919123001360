import { USER_REGISTER, USER_LOGIN } from '../action';
const INITIAL_STATE = { body: {}, user: {} };

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
  case USER_REGISTER:
    return { ...state, body: action.body };
  case USER_LOGIN:
    return { ...state, user: action.body };
  default:
    return state;
  }
}