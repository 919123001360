import React, { Component } from 'react';
import {
  
} from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from '../../constant/Utils';
import Header from '../common/Header';
import Footer from '../common/Footer';

import './member.css'; 
import '../common/common.css'; 

import { CMS_BASE_URL, login } from '../../redux/action';

import loginBanner from '../../images/backgroundBanner.png';

// const bcrypt = require('bcrypt');
const SHA256 = require("crypto-js/sha256");

class Login extends Component {

	constructor(props, context) {
	    super(props, context);
	    this.state = {
	    	title: "Login",
	    	email: "",
	    	password: "",
	    	errorEmail: false,
	    	errorPw: false,
	    	errorGeneral: false,
	    	errorPending: false
	    };
    }

	componentWillMount() {

	}

	validateLogin() {
		if (this.state.email == "") {
			this.setState({ errorEmail: true })
			return
		} else {
			this.setState({ errorEmail: false })
		}

		if (this.state.password == "") {
			this.setState({ errorPw: true })
			return
		} else {
			this.setState({ errorPw: false })
		}

		this.postLogin();
	}

	postLogin() {

		const hashedPassword = SHA256(this.state.password).toString();
			
		console.log(hashedPassword);

		login({ email: this.state.email, password: hashedPassword }).then(res => {
			console.log(res);
			if (res.status == 200) {
				if (res.data.token) {
					window.localStorage.setItem("token",res.data.token);
				}
			} else {
				this.setState({ errorEmail: true, errorPw: true });
			}
		}).catch(err => {
			this.setState({ errorEmail: true, errorPw: true });
		});
	}


	render() {

		return(
			<div className="geContainer">
				<Header urlKey={this.props.urlKey} />
				<div className="mainContainer">
					<div className="sectionContainer">
						<div className="headline accountBanner">
							<img className="banner" src={loginBanner} />
							
						</div>
						<div className="bodyContent containerMaxWidth memberContainer">
							<div className="inputBox">
								<span>Email address: </span>
								<input className="fieldInput" type="email" onChange={(e) => this.setState({ email: e.target.value }) } />
								<p className={this.state.errorEmail ? "errorRemark active" : "errorRemark"}>Please fill in a valid email address</p>
							</div>
							<div className="inputBox">
								<span>Password: </span>
								<input className="fieldInput" type="password" onChange={(e) => this.setState({ password: e.target.value }) } />
								<p className={this.state.errorPw ? "errorRemark active" : "errorRemark"}>Please fill in a valid password</p>
							</div>
							<div className="buttonBox" onClick={() => this.validateLogin()}>
								<div className="memberBtn actionBtn">
									<a><span>LOGIN</span></a>
								</div>
								<div className="memberBtn resetBtn actionBtn">
									<a><span>RESET PASSWORD</span></a>
								</div>
							</div>
							<p style={{ textAlign: 'center', position: 'relative' }} className={this.state.errorGeneral ? "errorRemark active" : "errorRemark"}>User does not exist</p>
							<p style={{ textAlign: 'center', position: 'relative' }} className={this.state.errorPending ? "errorRemark active" : "errorRemark"}>Account is under review</p>
						
							<div className="regRemark">
								<span>Do not have an account? Please click <a href="/register"><u>here</u></a> to sign up.</span>
							</div>
						</div>
					</div>
				</div>
				<Footer urlKey={this.props.urlKey} />
			</div>
		);
	}

}

const mapStateToProps = (state) => {
  return {

  };
}

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch)

export default withRouter(connect(
  mapStateToProps,
  {  }
)(Login));