import React, { Component } from 'react';
import {
  
} from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from '../../constant/Utils';
import Header from '../common/Header';
import Footer from '../common/Footer';

import { CMS_BASE_URL, register } from '../../redux/action';

import './member.css'; 
import '../common/common.css'; 

import regBanner from '../../images/backgroundBanner.png';

const SHA256 = require("crypto-js/sha256");

class Register extends Component {

	constructor(props, context) {
	    super(props, context);
	    this.state = {
	    	title: "Registration",
	    	name: "",
	    	reg_number: "",
	    	email: "",
	    	password: "",
	    	professional: "",
	    	cpw: "",
	    	s_code: "",
	    	checked: false,
	    	errorName: false,
	    	errorNumber: false,
	    	errorEmail: false,
	    	errorProf: false,
	    	errorPw: false,
	    	errorCpw: false,
	    	errorCode: false,
	    	errorCheck: false,
	    	errorGeneral: false,
	    	underReview: false
	    };
    }

	componentWillMount() {

	}

	validateReg() {
		if (this.state.name == "") {
			this.setState({ errorName: true })
			return
		} else {
			this.setState({ errorName: false })
		}

		let emailVal = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (this.state.email == "" || !emailVal.test(this.state.email)) {
			this.setState({ errorEmail: true })
			return
		} else {
			this.setState({ errorEmail: false })
		}

		if (this.state.password == "" || !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(this.state.password)) {
			this.setState({ errorPw: true })
			return
		} else {
			this.setState({ errorPw: false })
		}

		if (this.state.password != this.state.cpw) {
			this.setState({ errorCpw: true })
			return
		} else {
			this.setState({ errorCpw: false })
		}

		if (!this.state.checked) {
			this.setState({ errorCheck: true })
			return
		} else {
			this.setState({ errorCheck: false })
		}

		this.postReg();
	}

	postReg() {
		
		const hashedPassword = SHA256(this.state.password).toString();

		register({ email: this.state.email, password: hashedPassword, username: this.state.name, profile: "" }).then(res => {
			console.log(res);
			if (res.status == 200) {
				if (res.data.token) {
					window.localStorage.setItem("token",res.data.token);
					window.location.href = "/";
				}
			} else {
				this.setState({ errorEmail: true, errorPw: true });
			}
		}).catch(err => {
			this.setState({ errorEmail: true, errorPw: true });
		});

	}



	render() {

		return(
			<div className="geContainer">
				<Header urlKey={this.props.urlKey} />
				<div className="mainContainer accountBanner">
					<img className="banner" src={regBanner} />
					<div className="sectionContainer">
						<div className="headline containerMaxWidth">

						</div>
						<div className="bodyContent containerMaxWidth memberContainer registerContainer">
							<div className="inputBox">
								<span>Name: </span>
								<input className="fieldInput" type="text" onChange={(e) => this.setState({ name: e.target.value }) } />
								<p className={this.state.errorName ? "errorRemark active" : "errorRemark"}>Please fill in your name</p>
							</div>
							<div className="inputBox">
								<span>Email Address: </span>
								<input className="fieldInput" type="email" onChange={(e) => this.setState({ email: e.target.value }) } />
								<p className={this.state.errorEmail ? "errorRemark active" : "errorRemark"}>Please fill in a valid email address</p>
							</div>
							<div className="inputBox">
								<span>Password: </span>
								<input className="fieldInput" type="password" onChange={(e) => this.setState({ password: e.target.value }) } />
								<p className={this.state.errorPw ? "errorRemark active" : "errorRemark"}>Password must contains at least 8 characters with uppercase and lowercase letter, number and symbol</p>
							</div>
							<div className="inputBox">
								<span>Confirmed Password: </span>
								<input className="fieldInput" type="password" onChange={(e) => this.setState({ cpw: e.target.value }) } />
								<p className={this.state.errorCpw ? "errorRemark active" : "errorRemark"}>Password does not match </p>
							</div>
							<div className="inputBox" style={{ "display": "none" }}>
								<span>Security Code: </span>
								<input className="fieldInput" type="text" />
								<p className="errorRemark"></p>
							</div> 
							<div className="tncInput inputBox">
								<input type="checkbox" onChange={() => this.setState({ checked: !this.state.checked })} />
								<span>I confirm that I have read and agree to the <a href="/terms">Privacy Notice</a></span>
								<p className={this.state.errorCheck ? "errorRemark active" : "errorRemark"}>Please confirm your read the Terms and Conditions</p>
							</div>

							{
								(!this.state.underReview) ? (
									<div>
										<div className="buttonBox" onClick={() => this.validateReg()}>
											<div className="memberBtn actionBtn">
												<a><span>CONFIRM</span></a>
											</div>
											<div className="memberBtn resetBtn actionBtn">
												<a href="/"><span>CANCEL</span></a>
											</div>
										</div>
										<p style={{ textAlign: 'center', position: 'relative' }} className={this.state.errorGeneral ? "errorRemark active" : "errorRemark"}>Email address is already registered</p>
									</div>
								) : (
									<div className="underReviewPopup">
										<p>Your account is under review. We will notify you by email once the review is complete.</p>
										<div className="buttonBox" onClick={() => window.location.href = "/" }>
											<div className="confirmBtn memberBtn actionBtn">
													<a><span>CONFIRM</span></a>
											</div>
										</div>
									</div>
								)
							}

						</div>
						
					</div>
				</div>
				<Footer urlKey={this.props.urlKey} />
			</div>
		);
	}

}

const mapStateToProps = (state) => {
  return {

  };
}

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch)

export default withRouter(connect(
  mapStateToProps,
  {  }
)(Register));