import React, { Component } from 'react';
import {
  
} from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from '../../constant/Utils';
import Header from '../common/Header';
import Footer from '../common/Footer';

import ImageGallery from "react-image-gallery";
import { Gallery, Item } from 'react-photoswipe-gallery';

import styled from '@emotion/styled';
import {Phone} from '@emotion-icons/fa-solid';
import {Whatsapp} from '@emotion-icons/fa-brands/Whatsapp';


import { getHome, requestAgent } from '../../redux/action';
import "react-image-gallery/styles/css/image-gallery.css";
import 'photoswipe/dist/photoswipe.css'
import './property.css'; 


class Property extends Component {

	constructor(props, context) {
	    super(props, context);
	    this.state = {
	    	property: {},
	    	banners:[],
	    	propertyData: [
	    		{
	    			id: 1,
	    			name: "Room in Greater London, United Kingdom",
	    			type: "House",
	    			price: "298",
	    			paidType: "rental",
	    			thumbnail: "https://a0.muscache.com/im/pictures/b90b5ab0-909f-41a0-8d43-97ed918d5242.jpg?im_w=720",
	    			mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1189.2019428608005!2d-0.1698728727648829!3d51.52587985308254!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761ab9da0554ff%3A0xaebd7170b6e7a61b!2sLondon%20Marylebone%20Grendon%20Rooms!5e0!3m2!1sen!2shk!4v1718112638820!5m2!1sen!2shk"
	    		},
	    		{
	    			id: 2,
	    			name: "Little Venice Garden Flat",
	    			type: "Flat",
	    			price: "592",
	    			paidType: "rental",
	    			thumbnail: "https://a0.muscache.com/im/pictures/848e5c3d-0aec-4aac-a0e5-60121244cbde.jpg?im_w=1200",
	    			mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1241.3611349502262!2d-0.16831270160522457!3d51.51831110000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761bd82ae75b0f%3A0x627d4773a3987c5a!2sPark%20Lane%20Apartments%20Crawford%20Place!5e0!3m2!1sen!2shk!4v1718112821049!5m2!1sen!2shk"
	    		},
	    		{
	    			id: 3,
	    			name: "Luxury apartment in the heart of Kensington",
	    			type: "House",
	    			price: "1298",
	    			paidType: "rental",
	    			thumbnail: "https://a0.muscache.com/im/pictures/hosting/Hosting-U3RheVN1cHBseUxpc3Rpbmc6MjkyNzY4ODA%3D/original/516dcc52-06c1-4efe-99cf-664cebc4b9ba.jpeg?im_w=1200"
	    		},
	    		{
	    			id: 4,
	    			name: "St Albans Grove - 1 Bed Cottage in Kensington",
	    			type: "House",
	    			price: "798",
	    			paidType: "rental",
	    			thumbnail: "https://a0.muscache.com/im/pictures/9f8e380e-8619-423e-8d02-68727181906a.jpg?im_w=1200"
	    		},
	    		{
	    			id: 5,
	    			name: "Room in Greater London, United Kingdom",
	    			type: "House",
	    			price: "298000",
	    			paidType: "sale",
	    			thumbnail: "https://a0.muscache.com/im/pictures/b90b5ab0-909f-41a0-8d43-97ed918d5242.jpg?im_w=720",
	    			mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1189.2019428608005!2d-0.1698728727648829!3d51.52587985308254!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761ab9da0554ff%3A0xaebd7170b6e7a61b!2sLondon%20Marylebone%20Grendon%20Rooms!5e0!3m2!1sen!2shk!4v1718112638820!5m2!1sen!2shk"
	    		},
	    		{
	    			id: 6,
	    			name: "Little Venice Garden Flat",
	    			type: "Flat",
	    			price: "592000",
	    			paidType: "sale",
	    			thumbnail: "https://a0.muscache.com/im/pictures/848e5c3d-0aec-4aac-a0e5-60121244cbde.jpg?im_w=1200",
	    			mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1241.3611349502262!2d-0.16831270160522457!3d51.51831110000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761bd82ae75b0f%3A0x627d4773a3987c5a!2sPark%20Lane%20Apartments%20Crawford%20Place!5e0!3m2!1sen!2shk!4v1718112821049!5m2!1sen!2shk"
	    		},
	    		{
	    			id: 7,
	    			name: "Luxury apartment in the heart of Kensington",
	    			type: "House",
	    			price: "1298000",
	    			paidType: "sale",
	    			thumbnail: "https://a0.muscache.com/im/pictures/hosting/Hosting-U3RheVN1cHBseUxpc3Rpbmc6MjkyNzY4ODA%3D/original/516dcc52-06c1-4efe-99cf-664cebc4b9ba.jpeg?im_w=1200"
	    		},
	    		{
	    			id: 8,
	    			name: "St Albans Grove - 1 Bed Cottage in Kensington",
	    			type: "House",
	    			price: "798000",
	    			paidType: "sale",
	    			thumbnail: "https://a0.muscache.com/im/pictures/9f8e380e-8619-423e-8d02-68727181906a.jpg?im_w=1200"
	    		},
	    	],
	    	data: {
	    		tel: "12341234",
	    		floorplans: [
		    		{
					    original: "https://media.rightmove.co.uk/11k/10984/148889846/10984_33152073_FLP_00_0000.jpeg",
					    thumbnail: "https://media.rightmove.co.uk/11k/10984/148889846/10984_33152073_FLP_00_0000.jpeg",
					    width: 560,
					    height: 1072,
					  },
		    		{
					    original: "https://media.rightmove.co.uk/11k/10984/148889846/10984_33152073_IMG_07_0000.jpeg",
					    thumbnail: "https://media.rightmove.co.uk/11k/10984/148889846/10984_33152073_IMG_07_0000.jpeg",
					    width: 1024,
					    height: 725,
					  },
		    		{
					    original: "https://media.rightmove.co.uk/11k/10984/148889846/10984_33152073_FLP_00_0000.jpeg",
					    thumbnail: "https://media.rightmove.co.uk/11k/10984/148889846/10984_33152073_FLP_00_0000.jpeg",
					    width: 560,
					    height: 1072,
					  },
		    		{
					    original: "https://media.rightmove.co.uk/11k/10984/148889846/10984_33152073_IMG_07_0000.jpeg",
					    thumbnail: "https://media.rightmove.co.uk/11k/10984/148889846/10984_33152073_IMG_07_0000.jpeg",
					    width: 1024,
					    height: 725,
					  },
				  ],
	    		banners: [
	    		{
				    original: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/91aa5446-d832-4d06-acde-75243f50adf0.jpeg?im_w=1200",
				    thumbnail: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/91aa5446-d832-4d06-acde-75243f50adf0.jpeg?im_w=1200",
				  },
				  {
				    original: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/fa883e63-0311-4927-8396-247ac51d296c.jpeg?im_w=1200",
				    thumbnail: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/fa883e63-0311-4927-8396-247ac51d296c.jpeg?im_w=1200",
				  },
				  {
				    original: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/fea1b5c5-3fdf-448f-a4f8-c0d2b591483c.jpeg?im_w=1200",
				    thumbnail: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/fea1b5c5-3fdf-448f-a4f8-c0d2b591483c.jpeg?im_w=1200",
				  },
				  {
				    original: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/1e0ffcdc-b95f-4bd9-8c75-480de555a027.jpeg?im_w=1200",
				    thumbnail: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/1e0ffcdc-b95f-4bd9-8c75-480de555a027.jpeg?im_w=1200",
				  },
				  {
				    original: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/1e0ffcdc-b95f-4bd9-8c75-480de555a027.jpeg?im_w=1200",
				    thumbnail: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/1e0ffcdc-b95f-4bd9-8c75-480de555a027.jpeg?im_w=1200",
				  },
				  {
				    original: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/1e0ffcdc-b95f-4bd9-8c75-480de555a027.jpeg?im_w=1200",
				    thumbnail: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/1e0ffcdc-b95f-4bd9-8c75-480de555a027.jpeg?im_w=1200",
				  },
				  {
				    original: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/1e0ffcdc-b95f-4bd9-8c75-480de555a027.jpeg?im_w=1200",
				    thumbnail: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/1e0ffcdc-b95f-4bd9-8c75-480de555a027.jpeg?im_w=1200",
				  },
				  {
				    original: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/1e0ffcdc-b95f-4bd9-8c75-480de555a027.jpeg?im_w=1200",
				    thumbnail: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/1e0ffcdc-b95f-4bd9-8c75-480de555a027.jpeg?im_w=1200",
				  },
				  {
				    original: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/1e0ffcdc-b95f-4bd9-8c75-480de555a027.jpeg?im_w=1200",
				    thumbnail: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/1e0ffcdc-b95f-4bd9-8c75-480de555a027.jpeg?im_w=1200",
				  },
				  {
				    original: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/1e0ffcdc-b95f-4bd9-8c75-480de555a027.jpeg?im_w=1200",
				    thumbnail: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/1e0ffcdc-b95f-4bd9-8c75-480de555a027.jpeg?im_w=1200",
				  },
				  {
				    original: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/1e0ffcdc-b95f-4bd9-8c75-480de555a027.jpeg?im_w=1200",
				    thumbnail: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/1e0ffcdc-b95f-4bd9-8c75-480de555a027.jpeg?im_w=1200",
				  },
				  {
				    original: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/1e0ffcdc-b95f-4bd9-8c75-480de555a027.jpeg?im_w=1200",
				    thumbnail: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/1e0ffcdc-b95f-4bd9-8c75-480de555a027.jpeg?im_w=1200",
				  },
				  {
				    original: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/1e0ffcdc-b95f-4bd9-8c75-480de555a027.jpeg?im_w=1200",
				    thumbnail: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/1e0ffcdc-b95f-4bd9-8c75-480de555a027.jpeg?im_w=1200",
				  },
				  {
				    original: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/1e0ffcdc-b95f-4bd9-8c75-480de555a027.jpeg?im_w=1200",
				    thumbnail: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/1e0ffcdc-b95f-4bd9-8c75-480de555a027.jpeg?im_w=1200",
				  },
				  {
				    original: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/1e0ffcdc-b95f-4bd9-8c75-480de555a027.jpeg?im_w=1200",
				    thumbnail: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/1e0ffcdc-b95f-4bd9-8c75-480de555a027.jpeg?im_w=1200",
				  },
				  {
				    original: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/1e0ffcdc-b95f-4bd9-8c75-480de555a027.jpeg?im_w=1200",
				    thumbnail: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/1e0ffcdc-b95f-4bd9-8c75-480de555a027.jpeg?im_w=1200",
				  },
				  {
				    original: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/1e0ffcdc-b95f-4bd9-8c75-480de555a027.jpeg?im_w=1200",
				    thumbnail: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/1e0ffcdc-b95f-4bd9-8c75-480de555a027.jpeg?im_w=1200",
				  },
				  {
				    original: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/1e0ffcdc-b95f-4bd9-8c75-480de555a027.jpeg?im_w=1200",
				    thumbnail: "https://a0.muscache.com/im/pictures/miso/Hosting-927434818704751674/original/1e0ffcdc-b95f-4bd9-8c75-480de555a027.jpeg?im_w=1200",
				  },
    		],
	    	}
	    };
    }

	componentWillMount() {

	}

	componentDidMount() {

		const splits = window.location.href.split('/');

		console.log(splits[splits.length - 1]);

		let property = this.state.propertyData.filter(item => item.id == splits[splits.length - 1])[0];

		this.setState({ property: property, banners: [{original: property.thumbnail, thumbnail: property.thumbnail}, ...this.state.data.banners] });
		// getHome({}).then(res => {
		// 		this.setState({ 
					
		// 		})
		// 	}, this)
		
	}

	requestAgent() {
		let token = window.localStorage.getItem("token");
		if (token) {

			requestAgent({ "token": token, "apartment_id": this.state.property.id }).then((res) => {
				window.location.href = "/mission";
			});

		} else {
			window.location.href = "/login";
		}

	}


	render() {

		const floorPlans = (this.state.data.floorplans || []).map(item => {
											return (
												<div className="floorplanItem">
													<Item
											      original={item.original}
											      thumbnail={item.thumbnail}
											      width={item.width}
											      height={item.height}
											      cropped="true"
											    >
											      {({ ref, open }) => (
											        <img ref={ref} onClick={open} src={item.thumbnail} />
											      )}
											    </Item>
										    </div>
											)
										});

		return(
			<div>
				<Header />
				<div className="propertyContainer">
					
					<div className="">
						<div className="imageGallery">
							<ImageGallery items={this.state.banners} />
						</div>

						<div className="panelContainer">
							<div className="leftPanel">

								<h3>{this.state.property.name}</h3>
								<p><b>£{(this.state.property.price || "").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</b> {(this.state.property.paidType == "rental") ? "pcm" : ""}</p>


								<div className="propertySection">
									<h4>Details</h4>
									<div className="detailGrid">
										<div className="detailGridItem"><p>Let available date: <b>01/08/2024</b></p></div>
										<div className="detailGridItem"><p>Deposit: <b>£2,100</b></p></div>
										<div className="detailGridItem"><p>Min. Tenancy: <b>Ask agent</b></p></div>
										<div className="detailGridItem"><p>Council Tax: <b>Ask agent</b></p></div>
										<div className="detailGridItem"><p>Property Type: <b>Terraced</b></p></div>
										<div className="detailGridItem"><p>Bedrooms: <b>6</b></p></div>
										<div className="detailGridItem"><p>Size: <b>Ask agent</b></p></div>
										<div className="detailGridItem"><p>Parking: <b>Yes</b></p></div>
										<div className="detailGridItem"><p>Garden: <b>Yes</b></p></div>
										<div className="detailGridItem"><p>Accessibility: <b>Ask agent</b></p></div>
									</div>
								</div>


								<div className="propertySection">
									<div className="plansGallery">
										<Gallery>
											{floorPlans}
										</Gallery>
									</div>
								</div>


								<div className="propertySection">
									<h4>Key Features</h4>
									<div>
										<ul class="_1uI3IvdF5sIuBtRIvKrreQ"><li class="lIhZ24u1NHMa5Y6gDH90A">For Sale freehold with Vacant Possession</li><li class="lIhZ24u1NHMa5Y6gDH90A">1.59 acres (0.643 hectares) with a floor area of 24,396 sq ft</li></ul>
									</div>
								</div>

								<div className="propertySection">
									<h4>Description</h4>
									<div className="description">
										<p>
											The original red brick building is formed over two floors, with cellar. There is a two-storey and single storey extension, all being inter-connected to the main property. There are currently 37 en-suite bedrooms across the site, some of which benefit from balconies.  <br/><br/>Externally the site is surrounded to the north and west by an impressive red brick boundary wall dating from the original Retreat use. There is also garaging and associated car parking, grounds and landscaping.  <br/><br/>There are impressive established gardens and mature deciduous trees surrounding the property. The site extends to approximately 1.59 acres (0.643 hectares).<br/><br/>The site lies within the grounds of the Retreat estate adjacent to the University of York’s main campus to the east, with Walmgate Stray to the south. There is York Cemetery and Low Moor Allotments to the west.<br/><br/>There is St Lawrence Church of England Primary School, mixed residential and VITA student York to the north. The situation enjoys a unique urban rural setting, combining city centre proximity together with unrivalled access to nearby public green spaces surrounding the site. The property is approximately 1 mile from the heart of York city centre, providing excellent access on foot, bicycle, public transport and vehicle to the full range of sought after services and amenities in York. York Railway Station is 1.5 miles to the north west, being situated on the East Coast main line that offers a regular service to London in approximately 2 hours. There are also regular rail links to nearby Harrogate and Leeds. The train station is popular with commuters, residents and tourists alike
										</p>
									</div>
								</div>
							</div>

							<div className="rightPanel">
								<div className="stickyPanel">

									<a className="actionButton" onClick={() => this.requestAgent() }>
										<div>
											<Whatsapp size={24} />
											<span>Request Detail</span>
										</div>
									</a>

									<div className="mapFrame">
										<iframe src={this.state.property.mapUrl} width="100%" height="450" style={{ border: "none" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
									</div>
								</div>

								
							</div>

						</div>

					</div>

				</div>
				<Footer />
			</div>
		);
	}

}

const mapStateToProps = (state) => {
  return {

  };
}

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch)

export default withRouter(connect(
  mapStateToProps,
  {  }
)(Property));